import React, { Component } from 'react';

import { Container } from 'reactstrap';

import LeaderTable from '../components/LeaderTable';
import './LeaderScreenPage.css';
import leftBanner from '../images/leader_screen_left.png';
import rightBanner from '../images/leader_screen_right.png';

const NUM_ON_SCREEN = 8;
const SCREEN_DELAY = 10;

class LeaderCycle extends Component {
  constructor(props) {
    super(props);

    this.state = {winners: [], intervalId: null, refreshIntervalId: null, index: 0};

    this.updateData = this.updateData.bind(this);
    this.bumpIndex = this.bumpIndex.bind(this);
  }

  async updateData() {
    const request = await fetch(`/api/users?limit=30`);
    const results = await request.json();
    this.setState({winners: results.map((result, i) => {
      return {...result, ranking: i+1}
    })});
  }

  async componentDidMount() {
    await this.updateData();
    const intervalId = setInterval(this.bumpIndex, SCREEN_DELAY*1000);
    this.setState({intervalId});

    const refreshInterval = Math.ceil(this.state.winners.length / 8) * SCREEN_DELAY;
    const refreshIntervalId = setInterval(this.updateData, refreshInterval* 1000);

    this.setState({refreshIntervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    clearInterval(this.state.refreshIntervalId);
  }

  bumpIndex() {
    let index = (this.state.index + NUM_ON_SCREEN) % this.state.winners.length;
    if (index < NUM_ON_SCREEN) {
      index = 0;
    }
    this.setState({index});
  }

  render() {
    return (
      <LeaderTable winners={this.state.winners.slice(this.state.index, this.state.index + NUM_ON_SCREEN)} />
    );
  }

}

const leaderScreenPage = () => {
  return (
    <div className='leaderScreen'>
      <img alt='leaderboard banner left' className='leaderScreen__banner' src={leftBanner} />
      <div>
        <h1 className='leaderScreen__title'>Leader Board</h1>
        <Container>
          <LeaderCycle />
        </Container>
      </div>
      <img alt='leaderboard banner left' className='leaderScreen__banner--right' src={rightBanner} />
    </div>
  )
}

export default leaderScreenPage;