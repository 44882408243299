import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

const NotFoundPage = (props) => {
  return (
    <Container className="text-center">
      <h1 className='my-5'>Sorry, this page was not found.</h1>
      <Button tag={Link} to='/dashboard' color="primary" size="lg">Return home</Button>
    </Container>
  );
}

export default NotFoundPage;