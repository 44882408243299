import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Logout from './Logout';

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName:    this.props.firstName    || '',
      lastName:     this.props.lastName     || '',
      handle:       this.props.handle       || '',
      phone:        this.props.phone        || '',
      textPreference:  this.props.textPreference,
      teamNumber:   this.props.teamNumber   || '',
      mixupLetter:  this.props.mixupLetter  || '',
      grade:        this.props.grade        || '',
      submitting:   false
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMixupLetter = this.onChangeMixupLetter.bind(this);

    this.validateTournament = this.validateTournament.bind(this);
  }

  validateTournament() {
    return this.state.submitting ||
          !this.state.firstName ||
          !this.state.lastName ||
          !this.state.teamNumber ||
          !this.state.mixupLetter ||
          !this.state.grade
  }

  async onSubmit(e) {
    e.preventDefault();
    if (window.APP_FLAVOR !== 'MASTERS' && !this.validateTournament()) {
     /* return; */
    }

    this.setState({submitting: true});

    let data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      handle: !!this.state.handle ? this.state.handle : null,
    }

    if (window.APP_FLAVOR !== 'MASTERS') {
      data = {
        ...data,
        handle: !!this.state.handle ? this.state.handle : null,
        team: this.state.teamNumber,
        mixup: this.state.mixupLetter,
        grade: this.state.grade
      }
    } else{
      data = {
        ...data,
        phone_number: !!this.state.phone ? this.state.phone : null,
        prefers_to_text: this.state.textPreference
      }
    }

    await fetch(`/api/users/${this.props.ipad}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      credentials: 'include'
    });

    this.props.afterSubmit();

    this.setState({submitting: false});
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onChangeMixupLetter(e) {
    const value = e.target.value.toUpperCase();
    const filtered = value.replace(/[^a-z]/gi, '');
    let finalValue = filtered[0] || '';
    this.setState({mixupLetter: finalValue});
  }

  render() {
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="firstName">First name</Label>
            <Input bsSize="lg" type="text" name="firstName" id="firstName" onChange={this.onChange} value={this.state.firstName}/>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last name</Label>
            <Input bsSize="lg" type="text" name="lastName" id="lastName" onChange={this.onChange} value={this.state.lastName}/>
          </FormGroup>
          { this.props.showHandle && (
            <FormGroup>
              <Label for="handle">Handle (display name on leaderboard)</Label>
              <Input bsSize="lg" type="text" name="handle" id="handle" onChange={this.onChange} value={this.state.handle}/>
            </FormGroup>
          )}
          { window.APP_FLAVOR === 'MASTERS' && (
            <div>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="phonenumber">Phone number</Label>
                  <Input bsSize="lg" type="tel" name="phone" id="phone" onChange={this.onChange} value={this.state.phone}/>
                </FormGroup>
              </Col>
            </Row>
            
            <Row>

            </Row>
            </div>
          )}
          <Button className="mt-3" size="lg" color="primary" disabled={this.state.submitting} block>Submit</Button>
          <Logout/>
        </Form>
      </div>
    )
  }
}

export default ProfileForm;
