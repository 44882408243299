// Action creators

export const setTime = time => ({
  type: 'SET_TIME',
  time
});

export const addTime = nSeconds => ({
  type: 'ADD_TIME',
  nSeconds
});

export const tick = () => ({
  type: 'TICK'
});

export const setScore = score => ({
  type: 'SET_SCORE',
  score
})

export const syncGlobal = (senttime, servertime) => ({
  type: 'GLOBAL_SYNC',
  senttime,
  servertime
})
export const pauseTime = pauseState =>({type: 'PAUSE_TIME',pauseState})
export const pauseTimeCount = pauseCount =>({type: 'PAUSE_TIME_COUNT',pauseCount})

// Middleware

// The concept here is basically to debounce server syncs. On each tick, we'll set a two
// second timer and remove any existing ones. Once a tick hasn't fired in two seconds,
// it syncs the time with the server.
let timeSyncTimeout;
export const timeSyncer = store => next => action => {
  if (action.type === 'TICK') {
    if (timeSyncTimeout) {
      clearTimeout(timeSyncTimeout);
    }
    timeSyncTimeout = setTimeout(() => {syncTime(store)}, 2000);
  }
  return next(action);
}

const syncTime = async (store) => {
  const req = await fetch(`/api/users/me`, {credentials: 'include'});
  const res = await req.json();
  //store.dispatch(setTime(res.seconds_remaining));
  if(res.timer_started == null){
    store.dispatch(null);
  }else{
    store.dispatch(setTime(Math.floor(new Date(res.timer_started).getTime()/1000)));
    store.dispatch(setScore(res.points_cat1 + res.points_cat2 + res.points_cat3))
  }
}

export const syncScore = async (dispatch) => {
  const req = await fetch(`/api/users/me`, {credentials: 'include'});
  const res = await req.json();

  dispatch(setScore(res.points_cat1 + res.points_cat2 + res.points_cat3));
}

// Reducers
export const user = (state = {}, action) => {
  var maxTimer = 45*60;

  var curTime = Math.floor((new Date().getTime())/1000);

  var eventEndTime = new Date(8640000000000000); // max date to not break math below

  if(window.tournament !== undefined){
    eventEndTime = Math.floor(new Date(window.tournament.endDate).getTime()/1000);
  }

  var timerAutoStart = false;

  // Either 45 minutes from time start, or event end -- whichever comes first.
  var trueEnd = null;
  var time = action.type === 'SET_TIME' ? action.time : state.time;
  if(time != null){
    trueEnd = Math.min(time+maxTimer,eventEndTime);
  }else{
    trueEnd = eventEndTime;
  }
  var remaining = Math.max(0,trueEnd - curTime);

  if(trueEnd == eventEndTime){
    // return time to autostart
    timerAutoStart = (eventEndTime-curTime-maxTimer)

    remaining = Math.min(maxTimer,remaining);
  }

  switch(action.type) {
    case 'SET_TIME':
      return {...state, time: action.time, remaining: remaining, endTime: eventEndTime}
    case 'ADD_TIME':
  
      var offset = (state.time+(45*60))-curTime;
      var remainingTime = Math.max(0,offset);

      remainingTime += action.nSeconds;
      // project a time into the future, and mark the new 'start' of time.
      var finalTimestamp = (curTime+remainingTime)-(45*60);
      
      return {...state, time: Math.floor(finalTimestamp), remaining: remaining, endTime: eventEndTime}
    case 'PAUSE_TIME': // Better for redux state management but because there will be an overall redux update it seems to be better to do this feature using just react hooks
      return {...state,pauseState}
    case 'PAUSE_TIME_COUNT': // Better for redux state management but because there will be an overall redux update it seems to be better to do this feature using just react hooks
      return {...state,pauseCount}
    case 'TICK':
      return {...state, 
        time: state.time, 
        remaining: remaining, 
        endTime: eventEndTime, 
        timerAutoStart: timerAutoStart}
    case 'SET_SCORE':
      return {...state,score: action.score};
    case 'GLOBAL_SYNC':
      return {...state,timeOffset: action.senttime-action.servertime};
    default:
      return state;
  }
}
