import React from 'react';
import { Link } from 'react-router-dom';

import { Container, ListGroup, ListGroupItem } from 'reactstrap';

import BackButton from '../components/BackButton';
import PageTitle from '../components/PageTitle';

const CategorySelectorPage = (props) => {
  return (
    <Container>
      <PageTitle title="Categories" backButton={<BackButton destination="/dashboard" label="Home"/>}/>
      <ListGroup className="mt-4">
        <ListGroupItem color="primary">Math Classics - <i>Classic math competition questions</i></ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=1&difficulty=1"}>Easy</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=1&difficulty=2"}>Medium</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=1&difficulty=3"}>Difficult</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=1&difficulty=4"}>Elite</ListGroupItem>
      </ListGroup>

      <ListGroup className="mt-4">
        <ListGroupItem color="primary">Gardner Greats - <i>Mathematical puzzles popularized by Martin Gardner</i></ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=2&difficulty=1"}>Easy</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=2&difficulty=2"}>Medium</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=2&difficulty=3"}>Difficult</ListGroupItem>

      </ListGroup>
      <ListGroup className="mt-4">
        <ListGroupItem color="primary">Math Pulse - <i>Mathematical trivia</i></ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=3&difficulty=1"}>Easy</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=3&difficulty=2"}>Medium</ListGroupItem>
        <ListGroupItem tag={Link} to={"/questions?category=3&difficulty=3"}>Difficult</ListGroupItem>
      </ListGroup>
    </Container>
  )
}

export default CategorySelectorPage;