import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';

import ProfileForm from '../components/ProfileForm';

class WelcomePage extends Component {
  constructor(props) {
    super(props);

    // Set the cookie to identify iPad with backend
    document.cookie = `IPAD_NUMBER=${this.props.match.params.ipadNumber};path=/`;

    this.state = {
      isLoaded: false,
      exists: null,
      firstName: '',
    }
  }

  async componentDidMount() {
    const userInfoRequest = await fetch(`/api/users/${this.props.match.params.ipadNumber}`,{credentials: 'include'});
    if(userInfoRequest.status == 401){
      this.setState({
        isLoaded:true,
        exists:false,
        unauth: true
      });
    }
    if (userInfoRequest.status === 404) {
      this.setState({
        isLoaded: true,
        exists: false,
        unauth: false
      });
    } else {
      const userInfo = await userInfoRequest.json();
      this.setState({
        isLoaded: true,
        exists: true,
        unauth: false,
        firstName: userInfo.first_name
      });
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return ''
    }

    return (
      <Container>
        {this.state.unauth ? <AuthorizePage/> : <Register ipadNumber={this.props.match.params.ipadNumber}/>}
      </Container>
    )
  }
}

const Register = ({ ipadNumber }) => {
  return (
    <div>
      <h1 className='my-5'>Welcome! Register below.</h1>
      <ProfileForm ipad={ipadNumber} afterSubmit={() => {window.location.href = '/dashboard'}}/>
    </div>
  );
}


export default WelcomePage;