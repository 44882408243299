import React from 'react';

import { Container, Table } from 'reactstrap';

import BackButton from '../components/BackButton';
import Logo from '../images/main_banner.png';

const InstructionsPage = (props) => {
  //<img alt='Masters' className='side-banner' src={Logo} style={{maxWidth:"80%",maxHeight:"20em",display:"block",marginLeft:"auto",marginRight:"auto"}}/>
  return(
    <div>
    <Container>
    <BackButton destination='/' label='Home'/>
    <img alt='Masters' className='main-banner' src={Logo}/>
      <div className="mt-5 mb-3">
         <h2 className="text-center">How to Play</h2> 
       {/* <h2 className="text-center">Please click on this link <a href= "https://momath.org/mini-masters-info/" target="_blank"> https://momath.org/mini-masters-info/ </a>  to view the instructions</h2> */}
      </div>


       <h3>Timing</h3>
      <p>
        You can begin the First Round anytime between <strong>6:00 pm and 7:15 pm ET
        (New York).</strong> &nbsp;You will have 45 minutes to answer questions, starting from the moment you choose to view your first question. &nbsp;
        <strong> Please note that you need to join by 6:45 pm to have the full 45 minutes permitted; participants who join later will have less time to complete the round</strong>. &nbsp;
         If you would like the opportunity to purchase additional time (see below for more information), you need to start before 6:40 pm. &nbsp;
        Additional time may be purchased, but in no case will the preliminary round extend beyond 7:30 pm ET (New York).
      </p>
      <p>
        You can pause the timer at any time by clicking on the remaining time.
      </p>
      <p>Please note: times are subject to change depending on actual start time.</p>

      <h3>Before you begin</h3>
      <p>
        Choose a &ldquo;handle&rdquo; that will be used to display your progress on the leaderboard. &nbsp;From the home screen, tap &ldquo;Edit My Profile&rdquo; to input your desired handle.
      </p>

      <h3>During the game</h3>
      <p>
        Once the tournament begins, you may start answering questions. &nbsp;From the home screen, tap &ldquo;Competition.&rdquo; &nbsp;From there, you can choose a category &mdash; <i>Math Pulse</i> (trivia), <i>Gardner Greats</i> (logic puzzles), or <i>Math Classics</i> (contest math) &mdash; and a difficulty level.
      </p>
      <p>
        If you run out of time, you can make a <strong>pledge of an additional contribution to MoMath</strong> &mdash; that's real money! &mdash; to keep going. &nbsp;(You will be asked to fulfill your pledge after the competition round ends.) &nbsp;The game will give you a chance to make a pledge while you are in the middle of a question, in case you need more time to answer.
      </p>
      <p> 
        You will initially have 45 minutes to complete the First Round. &nbsp;If you are close to running out of time, you can pledge funds while you are in the middle of a question to receive additional time. &nbsp;However, you will only be able to purchase enough time to play right up until the final deadline for the First Round, at 7:30 pm.
      </p>

      <h3>Scoring</h3>

      <p>
        If you get a question right, you win points. &nbsp;If you get it wrong, points are deducted from your total. &nbsp;If you answer a question &mdash; incorrectly or correctly &mdash; you are locked out of that question and your score is adjusted.
      </p>
      <p>
        Questions are weighted based on difficulty; points are allocated based on level. &nbsp;More points are given for more difficult questions:
      </p>
      <Table style={{maxWidth:"33%"}} bordered >
        <tr className="table-info">
          <th>Level</th>
          <th className="text-nowrap text-center text-success">Correct</th>
          <th className="text-nowrap text-center text-danger">Incorrect</th>
        </tr>
        <tr>
          <th>Easy</th>
          <td className="text-nowrap text-center text-success">+100 points</td>
          <td className="text-nowrap text-center text-danger">-25 points</td>
        </tr>
        <tr>
          <th>Medium</th>	
          <td className="text-nowrap text-center text-success">+300 points</td>
          <td className="text-nowrap text-center text-danger">-75 points</td>
        </tr>
        <tr>
          <th>Difficult</th>
          <td className="text-nowrap text-center text-success">+1,000 points</td>
          <td className="text-nowrap text-center text-danger">-250 points</td>
        </tr>
        <tr>
          <th>Elite</th>
          <td className="text-nowrap text-center text-success">+2,500 points</td>
          <td className="text-nowrap text-center text-danger">-625 points</td>
        </tr>
      </Table>
      <p>
        Note that your cumulative point total can drop below zero.
      </p>

      <h3>Categories</h3>
      <p>
        There are three different categories of questions: <i>Math Pulse</i> (trivia), <i>Gardner Greats</i> (logic puzzles), and <i>Math Classics</i> (contest math). &nbsp;
        You may choose to focus on a single category or to answer questions from any mix of categories.&nbsp;
        Points from all categories will be added together to generate the final score, which is the primary component used to determine which participants will be invited to compete in the Finals Round.&nbsp;
        There are also prizes for the top scorer in each category.&nbsp;
        Not all categories have questions at the highest (elite) point level.
      </p>

      <h3>Collaboration</h3>
      <p>
        You are welcome to collaborate with whomever you like, but we ask that you refrain from using internet search engines or other non-human research sources. &nbsp;However, if you do work with someone else on a question, we ask that only one of you enter the answer into your contest iPad.
      </p>

      <h3>Help</h3>

      <p>MoMath staff are ready to help; please visit the Help Desk for any questions.</p>
    </Container>
    </div>
  );
  return (
    <Container>
      <div className="mt-5 mb-3">
        <BackButton destination='/' label='Home'/>
        <h1>How to play</h1>
      </div>
      <h2 className="mt-4">Before you begin</h2>
      <p>
        Come up with a &ldquo;handle&rdquo; that will be used to display your progress on the leaderboard.
        From the home screen, tap &ldquo;Edit My Profile&rdquo; to change your handle to a creative name of your choosing.
      </p>

      <h2 className="mt-4">During the game</h2>
      <p>
        Once the tournament begins, you can start answering questions. From the home screen, tap &ldquo;Questions.&rdquo;
        From there, you can choose a category—Math Pulse (trivia), Gardner Greats (logic puzzles), or Math Classics (contest math)—and a difficulty level.
      </p>
      <p>
        If you get a question right, you win points. If you get it wrong, points are deducted from your total.
        If you choose not to answer a question, you cannot go back to it.
      </p>
      <p>
        If you run out of time, you can make a <strong>pledge of an additional contribution to MoMath</strong>—that's
        real money 😊—to keep going. The game will give you a chance to donate while you are in the middle of a question, in case you need more time to answer.
      </p>
      <p> 
        You can pause the timer at any time by clicking on the remaining time.
      </p>
      <p>
        You will initially have 45 minutes to complete the Cocktail Round, but your remaining time is only used when you
        are viewing a question. If you are close to running out of time, you can donate funds while you are in the middle
        of a question to get additional time. However, you will only be able to purchase enough time to play right up
        until the final deadline for the Cocktail Round (when we get ready for dinner).
      </p>

      <h2 className="mt-4">Collaboration</h2>
      <p>
        You are welcome to collaborate with whomever you like. However, if you do work with anyone on a problem, please
        at most one of you enter the answer you come up with.
      </p>
    </Container>
  )
}

export default InstructionsPage;