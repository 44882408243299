import React, { Component }  from 'react';
import Logo from '../images/main_banner.png';
import { connect } from 'react-redux';

function specialPrint(number){
    if(number == null){
        return 0;
    }
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PageTitle extends Component {
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <div>
                <div style={{display:"flex",justifyContent:"space-between",marginTop:"1em",alignItems:"center"}}>
                    <div>
                        {this.props.backButton != null && this.props.backButton}
                        <h1 className="mt-4 mb-4">{this.props.title}</h1>
                        {/* {this.props.user.score !== 0 && <div className="mb-3" style={{fontSize: '1.25em'}}>Current score: <span style={{fontWeight: 'bold'}}>{specialPrint(this.props.user.score)} points</span></div>} */}
                    </div>
                    <img alt='Masters' className='center-banner' src={Logo} style={{maxHeight:"9em",height:"100%",maxWidth:"40vw"}}/>
                </div>
            </div>
        )
    }
}
PageTitle = connect(state => ({user: state}))(PageTitle);

export default PageTitle;