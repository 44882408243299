import React, { Component } from 'react';

import { Container } from 'reactstrap';

import BackButton from '../components/BackButton';
import LeaderTable from '../components/LeaderTable';

import leaderBanner from '../images/Masters_Leaderboard.png';

import logo from '../images/main_banner.png';

class LeaderBoardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervalId: null,
      hasLoaded: false,
      winners: []
    }

    this.refreshData = this.refreshData.bind(this);
  }

  async refreshData() {
    const request = await fetch(`/api/users`);
    if(request.status == 405){
      this.setState({winners: []});
      return;
    }
    try{
      const results = await request.json();
      this.setState({winners: results.map((result, i) => {
        return {...result, ranking: i+1}
      })});
    }catch (err){
      this.setState({winners:null});
    }

  }

  async componentDidMount() {
    await this.refreshData();
    this.setState({hasLoaded: true});

    const intervalId = setInterval(this.refreshData, 5000);
    this.setState({intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    if(this.state.winners.length === 0){
      return(
        <div>
          <img alt='Masters' className='main-banner' src={logo} />
          <Container className="text-center leader-container">
            <div className="my-5">
              {/* <h3>The leaderboard has been taken down for final processing.</h3> */}
              
              {/* <h3> The leaderboard will be available at the start of the competition on Tuesday, February 22, 2022 at 4:00 pm ET (New York).</h3> */}
              {  <h3>  The leaderboard is no longer available.  Join us upstairs after dinner for the final round of the 2023 MoMath Masters Tournament! </h3> }
            </div>
          </Container>
        </div>
      )
    }
    return (
      <div>
      <Container className="leader-container">
        {!this.props.guestMode && <BackButton destination="/dashboard" label="Home" />}
        <img alt='leaderboard banner' className='main-banner' src={leaderBanner} />
        { this.state.hasLoaded && (
          <LeaderTable winners={this.state.winners}/>
        )}
      </Container>
      </div>
    )
  }
}

export default LeaderBoardPage;