import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, ListGroup, ListGroupItem, Label } from 'reactstrap';

import BackButton from '../components/BackButton';
import PageTitle from '../components/PageTitle';

const questionItemStyle = {"fontSize": "1.2em"};

class QuestionListingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLoaded: false,
      questions: []
    }
  }

  async componentDidMount() {
    const questionRequest = await fetch(`/api/questions${this.props.location.search}`, {credentials: 'include'});
    const questions = await questionRequest.json();

    const userRequest = await fetch(`/api/users/me`, {credentials: 'include'});
    const userData = await userRequest.json();

    this.setState({
      hasLoaded: true,
      questions: questions,
      totalScore: userData.points_cat1 + userData.points_cat2 + userData.points_cat3
    });
  }

  render() {
    const questions = this.state.questions.map((question) => {
      const questionProps = {};

      if (question.status !== null) {
          switch(question.status) {
            case -1:
              questionProps.color = 'light';
              break;
            case 1:
              questionProps.color = 'light';
              break;

            default:
              break;
          }
    
      }

      if (!(window.APP_FLAVOR === 'MASTERS' && question.status)) {
        questionProps.to = `/questions/${question.question_id}`;
        questionProps.tag = Link;
      }

      questionProps.key = question.question_id;

      questionProps.style = questionItemStyle;

      return (
        <ListGroupItem {...questionProps} className="justify-content-between">
          Question {question.question_id}
        </ListGroupItem>
      );
    });


    let description = 'Questions';

    if (window.APP_FLAVOR === 'MASTERS') {
      const queries = this.props.location.search.replace('?', '').split('&');
      const queryVariables = {};
      for (let q of queries) {
        queryVariables[q.split('=')[0]] = parseInt(q.split('=')[1]);
      }

      let categories = [
        'Math Classics',
        'Gardner Greats',
        'Math Pulse'
      ];

      let difficulties = [
        'Easy',
        'Medium',
        'Difficult',
        'Elite'
      ];

      description = `${categories[queryVariables.category - 1]} – ${difficulties[queryVariables.difficulty - 1]}`;
    }
    var minVal = null;
    var maxVal = null;
    if(questions.length !== 0){
      minVal = this.state.questions[0].question_id;
      maxVal = this.state.questions[this.state.questions.length-1].question_id;
    }
  
    return (
      <Container>
        <PageTitle title={description} backButton={<BackButton destination={window.APP_FLAVOR !== 'MASTERS' ? '/' : '/selector'} label={window.APP_FLAVOR !== 'MASTERS' ? 'Home': 'Categories'} />}/>
        {this.state.hasLoaded && questions.length != 0 ?
        (<Label>Questions {minVal} through {maxVal}</Label>) : null}
        { this.state.hasLoaded && (
          questions.length !== 0 ? <ListGroup>{questions}</ListGroup> : "No questions are available at this time."
        )}
      </Container>
    )
  }
}

export default QuestionListingPage;