import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Input, Label, Button, Row, Col, Modal, ModalBody } from 'reactstrap';

import MathJax from 'react-mathjax2';
// import usePauseTimer from '../hooks/usePauseTimer';

import { tick,syncScore } from '../store';
import TimerWarning from '../components/TimerWarning'
import QuestionText from '../components/QuestionText';
import './QuestionPage.css';
import { IoTThingsGraph } from 'aws-sdk';

function specialPrint(number){
  if(number == null){
      return 0;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class QuestionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      modalIsOpen: false,
      correctStatus: null,
      errorMessage: null,
      question: {},
      warn: false
    }
    if(this.props.match){// implies hat we're here through react-router
      this.state.allquestions_view = false;
    }
    else{// means we're rendering through AllQuestions
      this.state.allquestions_view = true;
    }

    if(!this.state.allquestions_view){ 
      this.questionId = this.props.match.params.questionId;
    }
    else{ 
      this.questionId = this.props.questionId;
    }

    this.goBack = this.goBack.bind(this);
    this.submitAnswer = this.submitAnswer.bind(this);
  }

  async componentDidMount() {
    if(!this.props.time){
      await fetch(`/api/questions/timerAck`, {credentials: "include"});
    }
      const questionRequest = await fetch(`/api/questions/${this.questionId}`, {credentials: 'include'});
      if (questionRequest.status === 404) {
        this.goBack();
        return;
      } else if (questionRequest.status === 403 || questionRequest.status === 500) {
        var r = await questionRequest.json();
        if(r.id === "WARN_USER"){
          this.setState({isLoading: false, warn: true});
          return;
        }
        this.goBack();
        return;
      }
      const question = await questionRequest.json();
      this.setState({isLoading: false, question: question});

    }

  goBack() {
    if(this.props.history)
      this.props.history.goBack();
  }

  async submitAnswer(answer) {
    const submitRequest = await fetch(`/api/questions/${this.questionId}/responses`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({answer})
    });
    const results = await submitRequest.json();

    syncScore(this.props.dispatch);

    if (submitRequest.status !== 200) {
      this.setState({modalIsOpen: true, errorMessage: results.error});
    } else {
      this.setState({modalIsOpen: true, correctStatus: results.correct});
    }
    setTimeout(this.goBack, 1500);
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }
    if( !this.props.user.time || this.state.warn){
      return(
        <Container>
          <TimerWarning {...this.props}/>
        </Container>
      );
    }

    return (
      <div>
        <Container>
          {/* {this.state.totalScore !== 0 && <div className="mb-3" style={{fontSize: '1.25em'}}>Current score: <span style={{fontWeight: 'bold'}}>{specialPrint(this.props.user.score)} points</span></div>} */}
          <QuestionText id={this.questionId} text={this.state.question.question_text} image={this.state.question.figure_filename} />

            <MastersQuestion
              id={this.questionId}
              pauseState={this.props.pauseState} 
              updatePauseState={this.props.updatePauseState} 
              updatePauseCount={this.props.updatePauseCount} 
              pauseCount={this.props.pauseCount}
              options={[
                        this.state.question.option_a,
                        this.state.question.option_b,
                        this.state.question.option_c,
                        this.state.question.option_d,
                        this.state.question.option_e
                      ]}
              submit={this.submitAnswer}
              goBack={this.goBack}
              {...this.props}
            />
        </Container>

        <Modal isOpen={this.state.modalIsOpen} className='modal-dialog-centered' modalTransition={{ timeout: 2 }}>
          <ModalBody>
                <div className="text-center">
                  <div className="modalConfirmation">Answer submitted.</div>
                </div>  
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

QuestionPage = connect(state => ({user: state}))(QuestionPage);


class MastersQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: 'a',
      intervalId: null
    }

    if(!this.state.allquestions_view)
    {
      // this.tick = this.tick.bind(this);
      this.onUpdate = this.onUpdate.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
    }

  }
  
  async componentDidMount() {

    this.props.updatePauseState(false)
  }

  componentDidUpdate() {
    if ( this.props.pauseState) {
      this.props.goBack();
    }
  }

  onUpdate(e) {
    this.setState({answer: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();
    //clearInterval(this.state.intervalId);
    this.props.submit(this.state.answer);
  }

  render() {
    if (this.state.isLoading) {
      return null;
    }

    const options = this.props.options.map((o, i) => {
      const letter = 'abcde'[i];
      // script="../MathJax.js?config=TeX-MML-AM_CHTML" 
      return (
        <FormGroup key={i} style={{fontSize: '1.5em'}}>
          <Label>
            <Input type="radio" value={letter} name="answer" checked={this.state.answer === letter} onChange={this.onUpdate} autoComplete="off"/>
            {' '}
            <MathJax.Context
              input='tex'
              script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-MML-AM_CHTML"
              options={ {
                tex2jax: {
                    packages: ['base'],
                    inlineMath: [ ['$','$'], ['\\(','\\)'] ],
                    displayMath: [ ['$$','$$'], ['\[','\]'] ],
                    processEscapes: true
                }
              } }
            >
            <MathJax.Text text={o} />
            </MathJax.Context>
          </Label>
        </FormGroup>
      )
    });

    return (
      <Container>
        <Form onSubmit={this.onSubmit}>
          {options}
          <Row className="mt-5">
            <Col>
              <Button size="lg" type="button" color="danger" onClick={this.props.goBack} disabled={this.state.buttonsLocked} block>Go back</Button>
            </Col>
            <Col>
              <Button size="lg" type="submit" color="success" disabled={this.state.buttonsLocked} block>Submit answer</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}


MastersQuestion = connect(state => ({timeRemaining: state.remaining}))(MastersQuestion);

// const MastersConfirmation = () => {


//   return (
//     <div className="text-center">
//       {/* <div style={{fill: '#28a745'}}><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg></div> */}
//       <div className="mt-3">Submitted answer</div>
//     </div>
//   );
// }

export default QuestionPage;
