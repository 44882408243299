import React, { useState,useEffect } from 'react';
import { Redirect,Route,} from 'react-router-dom';
import { useAlert} from 'react-alert'
import { Button } from 'reactstrap';


const LogoutConfrimation = ({onSubmit}) =>{

    return( <div className='LogoutConfrimation'>
        <p>Are your sure you want to log out of the tournament</p>
        <button onClick={onSubmit}>
           Yes
        </button>
    </div>)
}

const Logout = ()=> {

    
    const [submited,setSubmit] = useState(0);
        
    const logoutAlert = useAlert()

    
 
    function delete_cookie(name) {
        console.log("I happened")
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }


    const onSubmit = () =>{
        setSubmit(submited + 1)
    }



    useEffect(()=>{
        if(submited === 1) logoutAlert.show(<LogoutConfrimation onSubmit={onSubmit}/>,{timeout:10000})
        if(submited === 2){
            delete_cookie("IPAD_NUMBER")
        }
    },[submited])

    return (<>
        <Button className="mt-3" size="lg" color="danger" onClick={onSubmit} block>Logout</Button>
    </>)


}

export default Logout
