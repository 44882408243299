import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Container, Form, FormGroup, Input, Label, Button, Row, Col, Modal, ModalBody } from 'reactstrap';

import banner from '../images/main_banner.png';

class AuthorizePage extends Component {
    constructor(props) {
        super(props);
    
        this.state = { submitting: false, message: '', error: false, contact:"text"};

        this.failedMessage = (
            <div>Your email address is not registered for this event.&nbsp; Please try again with your registered email (provided by your sponsor), or contact  MoMath for further assistance (email <a href="mailto:mastershelp@momath.org">mastershelp@momath.org</a> or call <a href="tel:212-542-0566">212-542-0566</a>).</div>
        );

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    async onSubmit(e) {
        e.preventDefault();

        if(!this.state.email){
            this.setState({submitting:false, message: "failed", error: true});
            return;
        }

         if(!this.state.phone && !this.state.optout){
            this.setState({submitting:false, message: "You must either enter a phone number or acknowledge you will not participate in the Finals Round.", error: true});
            return;
        } 

        let data = {email: this.state.email, phone_number: this.state.phone, text_preference: this.state.contact !== "call"};
        this.setState({ submitting: true });
        const g = await fetch(`/api/users/authorize_me`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            credentials: 'include'
        });
        if(g.status !== 200){
            this.setState({ submitting: false, message: "failed", error: true });
        }else{
            this.props.history.push('/confirm-email');
        }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }
    onClick(e) {
        this.setState({[e.target.name]: e.target.checked});
    }

    render() {
        return (
            <div>
                <img alt='banner' className='main-banner' src={banner} />
                <Container>
                    <div className="my-5">
                        <div>Check in for the competition rounds using your registered email address.</div>
                    </div>
                    {this.state.message.length > 0 && (
                        <Alert color={this.state.error ? "danger" : "success"}>{this.state.message === "failed" ? this.failedMessage : this.state.message}</Alert>
                    )}
                    <Form onSubmit={this.onSubmit}>
                        <FormGroup>
                            <Label for="email"><strong>Registered Email Address: </strong> </Label>
                            <Input bsSize="lg" type="email" name="email" id="email" onChange={this.onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="phone"><strong>Mobile Number:</strong> (required for advancement to the Finals Round)</Label>
                            <Input bsSize="lg" type="tel" name="phone" id="phone" disabled={this.state.optout} onChange={this.onChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label style={{paddingLeft:"1.25rem", color:(this.state.phone ? "#818182" : "")}}>
                                <Input type="checkbox" name="optout" value="1" onChange={this.onClick} disabled={this.state.phone}/>
                                <strong>I choose not to provide a phone number.</strong>&nbsp; By opting out, I acknowledge that I will not be eligible to participate in the Finals Round.&nbsp; All finalists must be reachable by phone or text message between 6:00 pm and 7:00 pm ET (New York) to confirm their participation in the Finals Round.
                            </Label>
                        </FormGroup>  
                         <FormGroup style={{color:(this.state.optout ? "#818182" : "")}} onChange={this.onChange}>
                            <Label for="contactoption">If you qualify for the Finals Round, would you prefer to be called or texted?</Label>
                            <FormGroup style={{paddingLeft: "2%"}}>
                                <Label>
                                    <Input type="radio" value="text" name="contact" disabled={this.state.optout} checked={this.state.contact!=="call"}/>
                                    Text message
                                </Label>
                            </FormGroup>
                            <FormGroup style={{paddingLeft: "2%"}}>
                                <Label>
                                    <Input type="radio" value="call" name="contact" disabled={this.state.optout} checked={this.state.contact==="call"}/>
                                    Call
                                </Label>
                            </FormGroup>
                        </FormGroup> 
                        <Button className="mt-3" size="lg" color="primary" disabled={this.state.submitting} block>Login</Button>
                    </Form>
                    <div className="my-4">
                        <p>Need help?  Please email <a href="mailto:mastershelp@momath.org">mastershelp@momath.org</a> or call <a href="tel:212-542-0566">212-542-0566</a>.</p>
                    </div>
                </Container>
            </div>
        )
    }
}

export default withRouter(AuthorizePage);