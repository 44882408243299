import React, { Component } from 'react';

import { Alert, Container } from 'reactstrap';

import BackButton from '../components/BackButton';
import ProfileForm from '../components/ProfileForm';
import PageTitle from '../components/PageTitle';

class EditProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLoaded: false,
      user: {},
      message: ''
    };

    this.afterUpdate = this.afterUpdate.bind(this);
  }

  async componentDidMount() {
    const response = await fetch(`/api/users/me`, {credentials: 'include'});
    const data = await response.json();
    this.setState({hasLoaded: true, user: data});
  }

  afterUpdate() {
    this.setState({message: 'Your profile has been updated;'});
  }

  render() {
    return (
      <Container>
        <PageTitle title="Your profile" backButton={<BackButton destination='/dashboard' label='Home'/>} noScore={true}/>
        {this.state.message.length > 0 && (
          <Alert color="success">{this.state.message} <a href="/dashboard">click here to return to competition dashboard.</a></Alert>
        )}
        {this.state.hasLoaded && (
          <ProfileForm
            ipad={this.state.user.ipad}
            showHandle={true}
            firstName={this.state.user.first_name}
            lastName={this.state.user.last_name}
            phone={this.state.user.phone_number}
            textPreference={this.state.user.prefers_to_text}
            teamNumber={this.state.user.team}
            mixupLetter={this.state.user.mixup}
            grade={this.state.user.grade}
            handle={this.state.user.handle}
            afterSubmit={this.afterUpdate}
          />
        )}
      </Container>
    );
  }
}

export default EditProfilePage;