import React, { Component } from 'react';
import { Button, Container, Form, FormGroup, Input, Label, Alert, Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';

class AddParticipantPage extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthorized: null, message: "", error:false }

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  async componentDidMount() {
    const res = await fetch(`/api/users/me`, {credentials: 'include'});
    if (res.status !== 200) {
      this.setState({isAuthorized: false});
      return;
    }
    const data = await res.json();
    this.setState({isAuthorized: data.admin || data.subadmin});
  }

  async onSubmit(e) {
    e.preventDefault();

    this.setState({submitting: true});
    
    let data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phone,
      prefers_to_text: this.state.contact !== "call",
      email: this.state.email,
      table: !this.state.table || this.state.table == 'None' ? null : this.state.table
    }

    let retData = await fetch(`/api/users/new`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      credentials: 'include'
    });
    try{
      let returned = await retData.json();

      this.setState({message: returned['confirm_link'], error:false});
    }catch (err) {
      console.error(err);
      this.setState({message: "Server returned error adding participant", error:true});
    }

    this.setState({submitting: false});
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  onCheck(e) {
    this.setState({[e.target.name]: e.target.checked});
  }

  render() {
    var tables = ['Perfect Partner', 'Elemental Benefactor/Integral Supporter - Guest', 'Prime Patron', 'MoMath Guest', 'Fibonacci Friend', 'Jade Vinson', 'MoMath - competitor', 'MoMath Trustee', 'MoMath Staff', 'Armen Avanessians/Goldman Sachs']
    
    var tableList = tables.map((t,i) => {
      return(
        <option>{t}</option>
      )
    });
    if (this.state.isAuthorized === null) {
      return null;
    }

    if (this.state.isAuthorized === false) {
      return (
        <Container>
          <h1 className="mt-5 mb-2">Oops!</h1>
          <p>Your account is not authorized to view this page.</p>
        </Container>
      );
    }

    return (
      <Container>

        {this.state.message.length > 0 && (
          <Alert color={this.state.error ? "danger" : "success"}>{this.state.message}</Alert>
        )}
        <h1 className="my-5">Add Participant/Generate Auth Link</h1>
        <Form onSubmit={this.onSubmit}>
          <FormGroup>
            <Label for="firstName">First name</Label>
            <Input bsSize="lg" type="text" name="firstName" id="firstName" onChange={this.onChange} value={this.state.firstName}/>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last name</Label>
            <Input bsSize="lg" type="text" name="lastName" id="lastName" onChange={this.onChange} value={this.state.lastName}/>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input bsSize="lg" type="email" name="email" id="email" onChange={this.onChange}/>
          </FormGroup>
          <FormGroup>
              <Label for="phone">Phone number (Your phone number will only be used to contact you in the event you are a finalist.)</Label>
              <Input bsSize="lg" type="tel" name="phone" id="phone" disabled={this.state.optout} onChange={this.onChange}/>
          </FormGroup>
          <FormGroup>
              <Label style={{paddingLeft:"1.25rem", color:(this.state.phone ? "#818182" : "")}}>
                  <Input type="checkbox" name="optout" value="1" onChange={this.onCheck} disabled={this.state.phone}/>
                  <strong>I choose not to provide a phone number.</strong>&nbsp; By opting out, I acknowledge that I will not be eligible to participate in the Finals Round.&nbsp; All finalists must be reachable by phone between 6:30 pm and 8:00 pm ET (New York) to confirm their participation in the Finals Round.
              </Label>
          </FormGroup>
          <FormGroup style={{color:(this.state.optout ? "#818182" : "")}} onChange={this.onChange}>
              <Label for="contactoption">If you qualify for the Finals Round, would you prefer to be called or texted?</Label>
              <FormGroup style={{paddingLeft: "2%"}}>
                  <Label>
                      <Input type="radio" value="text" name="contact" disabled={this.state.optout}/>
                      Text
                  </Label>
              </FormGroup>
              <FormGroup style={{paddingLeft: "2%"}}>
                  <Label>
                      <Input type="radio" value="call" name="contact" disabled={this.state.optout}/>
                      Call
                  </Label>
              </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label for="table">Table</Label><br/>
            <Input type="select" name="table" id="table" onChange={this.onChange}>
              <option>None</option>
              {tableList}
            </Input>
          </FormGroup>
          <Button className="mt-3" size="lg" color="primary" disabled={this.state.submitting} block>Submit</Button>
        </Form>
      </Container>
    );
  }
}

export default AddParticipantPage