import React from 'react';
import { Link } from 'react-router-dom';

const BackButton = (props) => {
  return (
    <div className="mb-4" style={{marginLeft: "-1em"}}>
      <Link className="d-inline-flex" to={props.destination}>
        <svg xmlns="http://www.w3.org/2000/svg" className="align-self-center" style={{fill: "currentColor"}} width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
        <div className="align-self-center pl-1" style={{fontSize: '1.15rem'}}>{props.label}</div>
      </Link>
  </div>
  )
}

export default BackButton;