import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Form, FormGroup, Input, Label, Button, Row, Col, Modal, ModalBody } from 'reactstrap';


import { tick } from '../store';
import QuestionText from '../components/QuestionText';
import QuestionPage from '../containers/QuestionPage';
import './QuestionPage.css';

class AllQuestions extends Component {  
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            allquestions : {}
        }
    }


    async componentDidMount() {

        const questionsRequest = await fetch(`/api/admin/allquestions`, {credentials: 'include'});
        if (questionsRequest.status === 404) {
            this.goBack();
            return;
        } else if (questionsRequest.status === 403) {
            this.goBack();
            return;
        }
        const questions = await questionsRequest.json();
        this.setState({isLoading: false, allquestions: questions});
    }


    goBack() {
        this.props.history.goBack();
    }




    render() {
        if (this.state.isLoading) {
          return null;
        }
        
        const questions = this.state.allquestions.map((q, i) => {
            return (
                  <QuestionPage key={q.id} questionId={q.id} ></QuestionPage>
            )

        });
    
        return (
          <div>
            {questions}
          </div>
        )
      }

}

export default AllQuestions;