import React from 'react';

import MathJax from 'react-mathjax2';



const QuestionText = ({ id, text, image }) => {
  return (
    <div>
      <MathJax.Context
        input='tex'

        script="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-MML-AM_CHTML"
        
        options={ {
          tex2jax: {
              packages: ['base'],
              inlineMath: [ ['$','$'], ['\\(','\\)'] ],
              displayMath: [ ['$$','$$'], ['\[','\]'] ],
              processEscapes: true
          }
        } }
      >
      <div className="mt-5 mb-3 questionText">{id}. <MathJax.Text text={text} /></div>
      </MathJax.Context>
      {!!image && (
        <div className="questionImage-wrapper">
          <img className="questionImage" src={`/images/${image}`} alt='Question figure'></img>
        </div>
      )}
    </div>
    
  )
}

export default QuestionText;