import React, { Component } from 'react';
import { Button, Container, Form, FormGroup, Input } from 'reactstrap';

import ResultsTable from '../components/ResultsTable';

class RoundSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLoaded: false,
      currentValue: null,
      originalValue: null,
      hasChanged: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const roundRequest = await fetch(`/api/tournament`, {credentials: 'include'});
    const roundData = await roundRequest.json();
    const round = parseInt(roundData.round, 10);
    this.setState({hasLoaded: true, currentValue: round, originalValue: round});
  }

  async onSubmit(e) {
    e.preventDefault();
    const request = await fetch(`/api/tournament`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({round: this.state.currentValue})
    });
    if (request.status === 200) {
      this.setState({originalValue: this.state.currentValue});
    }
  }

  render() {
    if (!this.state.hasLoaded) {
      return '';
    }

    return (
      <div>
        <h2>Round selection</h2>
        <Form inline onSubmit={this.onSubmit}>
          <FormGroup>
            <Input type="number" id="roundSelection" defaultValue={this.state.currentValue} onChange={(e) => { this.setState({currentValue: parseInt(e.target.value, 10)}) }}/>
          </FormGroup>
          {this.state.currentValue !== this.state.originalValue && <Button className="ml-3" type="submit">Submit</Button>}
        </Form>
      </div>
    )
  }
}

class TournamentResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowingIndResults: false,
      isShowingMixupResults: false,
      isShowingTeamResults: false,
      teamResults: [],
      mixupResults: [],
      lowerDivResults: [],
      upperDivResults: []
    }

    this.getIndResults = this.getIndResults.bind(this);
    this.getTeamResults = this.getTeamResults.bind(this);
    this.getMixupResults = this.getMixupResults.bind(this);
    this.toggleTeamResults = this.toggleTeamResults.bind(this);
    this.toggleMixupResults = this.toggleMixupResults.bind(this);
    this.toggleIndResults = this.toggleIndResults.bind(this);
  }

  async getIndResults() {
    const indResultRequest = await fetch(`/api/admin/scores/individual`, {credentials: 'include'});
    const indResults = await indResultRequest.json();
    this.setState({lowerDivResults: indResults.lowerDivision, upperDivResults: indResults.upperDivision});
  }

  async getMixupResults() {
    const mixupResultRequest = await fetch(`/api/admin/scores/mixup`, {credentials: 'include'});
    const mixupResults = await mixupResultRequest.json();
    this.setState({mixupResults});
  }

  async getTeamResults() {
    const teamResultRequest = await fetch(`/api/admin/scores/momathlon-teams`, {credentials: 'include'});
    const teamResults = await teamResultRequest.json();
    this.setState({teamResults});
  }

  async toggleIndResults() {
    if (!this.state.isShowingTeamResults) {
      await this.getIndResults();
    }
    this.setState({isShowingIndResults: !this.state.isShowingIndResults});
  }

  async toggleMixupResults() {
    if (!this.state.isShowingTeamResults) {
      await this.getMixupResults();
    }

    this.setState({isShowingMixupResults: !this.state.isShowingMixupResults});
  }

  async toggleTeamResults() {
    if (!this.state.isShowingTeamResults) {
      await this.getTeamResults();
    }
    this.setState({isShowingTeamResults: !this.state.isShowingTeamResults});
  }

  render() {
    return (
      <div>
        <div>
          <h2 className="mt-5">Individual results</h2>
          <div className="my-3">
            <Button color="primary" size="sm" onClick={this.toggleIndResults} outline>{ this.state.isShowingIndResults ? 'Hide' : 'Show' } individual results</Button>
          </div>
          {this.state.isShowingIndResults && (
            <div className="mt-3">
              <h3>Upper Division</h3>
              <ResultsTable values={this.state.upperDivResults}/>
              <h3>Lower Division</h3>
              <ResultsTable values={this.state.lowerDivResults}/>
            </div>
          )}
        </div>

        <div>
          <h2 className="mt-5">Mixup results</h2>
          <div className="my-3">
            <Button color="primary" size="sm" onClick={this.toggleMixupResults} outline>{ this.state.isShowingMixupResults ? 'Hide' : 'Show' } mixup results</Button>
          </div>
          {this.state.isShowingMixupResults && (
            <ResultsTable values={this.state.mixupResults}/>
          )}
        </div>

        <div>
          <h2 className="mt-5">Final results</h2>
          <div className="my-3">
            <Button color="primary" size="sm" onClick={this.toggleTeamResults} outline>{ this.state.isShowingTeamResults ? 'Hide' : 'Show' } final team results</Button>
            <Button color="secondary" size="sm" className="ml-2" href="/api/admin/scores/momathlon-teams.csv" outline>↓ Download</Button>
          </div>

          {this.state.isShowingTeamResults && (
            <ResultsTable values={this.state.teamResults}/>
          )}
        </div>
      </div>
    );
  }
}

const AdminPages = () => {
  return(
    <>
    <h2>Admin Pages</h2>
    <Button outline color="primary" href="admin/create-participant">Create participants (or auth link)</Button>
    </>
  )
}

const MastersResults = () => {
  return (
    <>
    <h2>Results</h2>
    <Button outline color="primary" href="/api/admin/scores/individual.csv">↓ Download individual results</Button>
    <Button outline color="primary" href="/api/admin/scores/categories.csv">↓ Download category results</Button>
    </>
  );
}

class Donations extends Component {
  constructor(props) {
    super(props);

    this.state = {donations: []};
  }

  async componentDidMount() {
    const req = await fetch(`/api/admin/donations`, {credentials: 'include'});
    const res = await req.json();
    this.setState({donations: res});
  }

  render() {
    return (
      <div>
        <h2>Donations</h2>
        <div className="my-3">
          <Button color="primary" size="sm" className="ml-2" href="/api/admin/donations.csv" outline>↓ Download</Button>
        </div>
        <ResultsTable values={this.state.donations}/>
      </div>
    );
  }
}


class QuestionPrintout extends Component { 
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const req = await fetch(`/api/admin/donations`, {credentials: 'include'});
    const res = await req.json();
    this.setState({donations: res});
  }

  render() {
    return (
      <div>
        <h2>Questions Printout</h2>
        <div className="my-3">
          <Button color="primary" size="sm" className="ml-2" href="/admin/AllQuestions" outline>Render All for Review</Button>
        </div>
      </div>
    );
  }
}




const ExportDatabase = () => {
  return (
    <div>
      <h2 className="mt-5">Export Database Zipfile</h2>
      <Button color="danger" size="sm" href="/api/admin/datadump.zip" outline>↓ Export Zip</Button>
    </div>
  );
}

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthorized: null }
  }

  async componentDidMount() {
    const res = await fetch(`api/users/me`, {credentials: 'include'});
    if (res.status !== 200) {
      this.setState({isAuthorized: false});
      return;
    }
    const data = await res.json();
    this.setState({isAuthorized: data.admin});
  }

  render() {
    if (this.state.isAuthorized === null) {
      return null;
    }

    if (this.state.isAuthorized === false) {
      return (
        <Container>
          <h1 className="mt-5 mb-2">Oops!</h1>
          <p>Your account is not authorized to view this page.</p>
        </Container>
      );
    }

    return (
      <Container>
        <h1 className="my-5">Admin</h1>
        {window.APP_FLAVOR !== 'MASTERS' && (
          <div>
            <RoundSelection/>
            <TournamentResults/>
            <QuestionPrintout/>
            <ExportDatabase/>
          </div>
        )}
        {window.APP_FLAVOR === 'MASTERS' && (
          <div>
            <div className="mb-5"><RoundSelection/></div>
            <div className="mb-5"><AdminPages /></div>
            <div className="mb-5"><MastersResults /></div>
            <div className="mb-5"><QuestionPrintout/></div>
            <div className="mb-5"><Donations /></div>
          </div>
        )}
      </Container>
    );
  }
}





export default AdminPage;