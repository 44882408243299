import React, { Component } from 'react';
import { Redirect, BrowserRouter as Router, Route, Switch, matchPath } from 'react-router-dom';
import { connect } from 'react-redux'

import { Container } from 'reactstrap';

import WelcomePage from './WelcomePage';
import QuestionListingPage from './QuestionListingPage';
import CategorySelectorPage from './CategorySelectorPage';
import QuestionPage from './QuestionPage';
import FinalRoundPage from './FinalRoundPage';
import DashboardPage from './DashboardPage';
import AdminPage from './AdminPage';
import InstructionsPage from './InstructionsPage';
import NotFoundPage from './NotFoundPage';
import LeaderBoardPage from './LeaderBoardPage';
import LeaderScreenPage from './LeaderScreenPage';
import EditProfilePage from './EditProfilePage';
import Navbar from './Navbar';
import AlertBar from './AlertBar';
import AllQuestions from './AllQuestions';
import AddParticipantPage from './AddParticipantPage';
import ConfirmedPage from './ConfirmedPage';

import AuthorizePage from './AuthorizePage';
import CheckinForm from '../components/CheckinForm'
import logo from '../images/momath_logo.png';
import leftBanner from '../images/leader_screen_left.png';
import rightBanner from '../images/leader_screen_right.png';

import './App.css';

import { setTime, store,tick,setScore,syncGlobal } from '../store';

class RequiresAuth extends Component {
  constructor(props) {
    super(props);

    this.hasCookie = document.cookie.includes('IPAD') && (process.env.AUTH_MODE !== 'ONLINE' || document.cookie.includes('auth_token'));

    this.state = {
      hasLoaded: false,
      userExists: false,
      pauseCount:0 ,
      pauseState:false,
      
    }
    this.updatePauseCount = this.updatePauseCount.bind(this)
    this.updatePauseState = this.updatePauseState.bind(this)
  }

  async componentDidMount() {
    if (!this.hasCookie) return;

    const req = await fetch(`/api/users/me`, {credentials: 'include'});
    if(req.status === 401){
      this.setState({userExists: false, hasLoaded: false, unauth: true});
      return;
    }
    // Equivalent of old session (cookie for no longer existing user)
    else if (req.status === 404) {
      this.setState({userExists: false, hasLoaded: true, unauth: false});
      return;
    }

    const data = await req.json();


    if(data.timer_started == null){
      this.props.dispatch(setTime(null));
    }else{
      this.props.dispatch(setScore(data.points_cat1 + data.points_cat2 + data.points_cat3));
      this.props.dispatch(setTime(Math.floor(new Date(data.timer_started).getTime()/1000)));
    }

    this.setState({userExists: true, hasLoaded: true, unauth: false});
  }
  updatePauseState(boolean){
    this.setState({pauseState:boolean})
  }
  updatePauseCount(number){
    if(!number){
      this.setState({pauseCount:this.state.pauseCount + 1})
    }
    else{ this.setState({pauseCount: number})}

  }

  render() {
    const isAuthorizing = matchPath(this.props.location.pathname,{path:"/auth/:id/:authtoken",exact:true,strict:false});
    const isLeaderboard = window.location.host.includes("leaderboard.momath.org");
    if(isLeaderboard){
      return(
        <div className="mb-4">
          <LeaderBoardPage guestMode="true"/>
        </div>
      );
    }
    
    if(isAuthorizing){
      var authtoken = isAuthorizing.params.authtoken;
      document.cookie = `IPAD_NUMBER=${isAuthorizing.params.id};path=/`
      document.cookie = `AUTH_TOKEN=${authtoken};path=/`
      this.state.unauth = false;
      this.hasCookie = true;

      return(
        <Redirect to={`/`}/>
      );
    }

    if (this.state.unauth || !this.hasCookie){
      return (
        <CheckinForm />
      );
    }

    if (!this.state.hasLoaded) return null;

    if (!this.state.userExists) {
      return (
        <Redirect to={`/user/login/${document.cookie.split('IPAD')[1].split('=')[1].split(';')[0]}`}/>
      );
    }

    return (
      <div className="mb-4">
        <Navbar  pauseState={this.state.pauseState} updatePauseState={this.updatePauseState} updatePauseCount={this.updatePauseCount} pauseCount={this.state.pauseCount} costPerMinute={this.props.tournament.costPerMinute}/>
        <AlertBar />
        <Switch>
          <Route exact path="/" component={EditProfilePage}/>
          <Route exact path="/dashboard" component={DashboardPage}/>
          <Route exact path="/selector" component={CategorySelectorPage}/>
          <Route exact path="/instructions" component={InstructionsPage}/>
          <Route exact path="/admin" component={AdminPage}/>  
          <Route exact path="/admin/create-participant" component={AddParticipantPage}/>
          <Route exact path="/questions" component={QuestionListingPage}/>
          <Route exact path="/questions/:questionId" render={(props)=><QuestionPage {...props} pauseState={this.state.pauseState} updatePauseState={this.updatePauseState} updatePauseCount={this.updatePauseCount} pauseCount={this.state.pauseCount}/>}/>
          <Route exact path="/finals" component={FinalRoundPage}/>
          <Route exact path="/profile" component={EditProfilePage}/>
          <Route exact path="/leaderboard" component={LeaderBoardPage}/>
          <Route exact path="/auth/:authtoken" component={AuthorizePage}/>
          <Route exact path="/confirm-email">
            <Redirect to="/dashboard"/>
          </Route>
          <Route component={NotFoundPage}/>
        </Switch>
      </div>
    );
  }
}

RequiresAuth = connect()(RequiresAuth);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      tournament: {}
    };
    this.tick = this.tick.bind(this);
    this.checkStart = this.checkStart.bind(this)
    setInterval(this.tick,1000);
  }

  tick(){
    this.props.dispatch(tick());
  }

  async checkStart(){
    if(this.state.tournament.started){
      return;
    }
    var tournRequest = await fetch(`/api/tournament`, {credentials: 'include'});
    var tourn = await tournRequest.json();
    window.tournament = tourn;
    if(tourn.started){
      console.log("Tournament Start",tourn.started)
      window.location.reload();
      clearInterval(this.intv);
    }
  }

  async componentDidMount() {
    var curTime = new Date().getTime();
    const metaRequest = await fetch(`/api/tournament`, {credentials: 'include'});
    const metadata = await metaRequest.json();
    window.APP_FLAVOR = metadata.app_flavor;
    window.tournament = metadata;
    this.setState({isLoaded: true, tournament: metadata});

    if(!metadata.started){
      //set up autorefresh
      this.intv = setInterval(this.checkStart,5000);
    }

    this.props.dispatch(syncGlobal(curTime,metadata.server_time));
  }

  render() {
    if (!this.state.isLoaded) {
      return null;
    }

    return (
      <div>
        <Router>
          <div>
            <Switch>
              <Route exact path="/user/login/:ipadNumber" component={WelcomePage}/>
              <Route exact path="/leaderscreen" component={LeaderScreenPage}/>
              <Route exact path="/admin/AllQuestions" component={AllQuestions}/>
              <RequiresAuth tournament={this.state.tournament}/>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}
App = connect()(App);

export default App;
