import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Container, Form, FormGroup, Input, Label, Button, Row, Col, Modal, ModalBody } from 'reactstrap';

class TimerWarning extends Component {
    constructor(props) {
        super(props);
    
        this.state = { submitting: false };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

        this.takeBack = this.takeBack.bind(this);

    }

    async componentDidMount() {
        if(this.props.user.timerAutoStart < 0 ){
            // User's timer is counting down anyways.
            var acknowledged = await fetch(`/api/questions/timerAck`, {credentials: "include"});
            window.location.reload();
        }
    }

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ submitting: true });
        var acknowledged = await fetch(`/api/questions/timerAck`, {credentials: "include"});

        window.location.reload();
    }

    takeBack(e){
        console.log("I am the this.takeBack",this.props.history)
        this.props.history.push("/dashboard")
        // if(this.props.history){
        //     this.props.history.goBack();
        // }else{
        //     window.location.href = "/dashboard";
        // }
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        return (
            <div>
                <div className="my-5">
                    <Alert color="warning" fade="false">
                    <h1>WAIT!</h1>
                    </Alert>
                    <h5>You're about to enter a question. This action will start the 45-minute timer, and at that point there's no going back. Are you ready to have fun with math?</h5>
                </div>
                <Form onSubmit={this.onSubmit}>
                    <Button className="mt-3" size="lg" color="primary" disabled={this.state.submitting} block>I understand</Button>
                </Form>
                <Button className="mt-3" size="lg" color="dark" disabled={this.state.submitting} onClick={this.takeBack} block>Take me back for now</Button>
            </div>
        )
    }
}

TimerWarning = connect(state => ({user: state}))(TimerWarning)

export default TimerWarning;