import React, { Component, useEffect,useState } from 'react';
import moment from "moment"
import { Link } from 'react-router-dom';
import { connect,useDispatch } from 'react-redux';
import { Button, Nav, Navbar, NavbarBrand, NavItem, Popover, PopoverBody } from 'reactstrap';
import {addTime,pauseTime,pauseTimeCount} from "../store"
import usePauseTimer from '../hooks/usePauseTimer';
import BuyTimeForm from '../components/BuyTimeForm';

class BuyTimeNavElement extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {popoverOpen: false};
  }

  toggle() {
    this.setState({popoverOpen: !this.state.popoverOpen});
  }

  toggleTime(){
    
  }

  render() {
    return (
      <div>
        <NavItem id="purchasePopover" onClick={this.toggle}>
          {/* ! Below is the code for add time button*/ }
           <Button color="primary" size="sm" >+ Add time</Button>
        </NavItem>
      
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target="purchasePopover" toggle={this.toggle}>
          <PopoverBody>
            <BuyTimeForm costPerMinute={this.props.costPerMinute}/>
          </PopoverBody>
        </Popover>
     </div>
    );
  }
}

let Clock = ({user,updatePauseState,pauseState,pauseCount,updatePauseCount,...props}) => {
  
  let remaining = user.remaining;
  const [currentPauseTime,setCurrentPauseTime] = useState()
  const dispatch = props.dispatch

  useEffect(()=>{
    if(pauseState && !currentPauseTime){
      setCurrentPauseTime(moment())
    }
    else if (!pauseState && currentPauseTime){
      const diff = currentPauseTime.diff(moment(),'seconds')
        fetch(`/api/users/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({additional_time: Math.abs(diff) })
      }).then(res => res)
      // window.location.reload()
      dispatch({
        type: 'ADD_TIME',
        nSeconds:Math.abs(diff)
      })
      setCurrentPauseTime(undefined)
    }
    else if (currentPauseTime && remaining === 0){
        const diff = currentPauseTime.diff(moment(),'seconds')
        fetch(`/api/users/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({additional_time: Math.abs(diff) })
      }).then(res => res)

      dispatch({
        type: 'ADD_TIME',
        nSeconds:Math.abs(diff)
      })
      setCurrentPauseTime(undefined)
    }
  },[pauseState,currentPauseTime,remaining,dispatch])



  const handleClick = ( ) => { 
    if(remaining !== 0){
      updatePauseState(true)
    }
  }



  if(window.tournament === undefined){
    return( 
      <div className="text-light mr-3" style={{display: 'flex', alignItems: 'center'}}>
          <div className="d-sm-none" style={{display: 'inline-block', textTransform: 'uppercase', fontSize: '0.75em'}}>Time</div>
          <div className="pauseButton">  
          <div className="d-none d-sm-block" style={{display: 'inline-block', textTransform: 'uppercase', fontSize: '0.75em',paddingRight:".5em"}}>Click to pause - Time remaining</div>
        </div>
      </div>
    );
  }
  if (!pauseState){
    return ( 
      <div className="text-light mr-3" style={{display: 'flex', textTransform: 'uppercase',alignItems: 'center'}} onClick={handleClick}>
        <div className="d-sm-none" style={{display: 'inline-block', fontSize: '0.75em'}}>Time</div>
        <div className="d-none d-sm-block" style={{display: 'inline-block',  fontSize: '0.75em',width:'240px'}}>
          <div className="pauseButton"> 
            Click to pause
          </div>
          <div className='timeRemain'>
           Time remaining:
          </div>
        </div>
        <div classname='timerdisplay'>
          {`${Math.floor(remaining / 60)}:${('0' + remaining % 60).slice(-2)}`}
        </div>
      </div>
    );
  }
  else{
    return (
    <div> 
      <div className="text-light mr-3" style={{display: 'flex', alignItems: 'center'}} onClick={handleClick}>
      <div className="d-sm-none" style={{display: 'inline-block', textTransform: 'uppercase', fontSize: '0.75em'}}>Time</div>
      <div className="d-none d-sm-block" style={{display: 'inline-block', textTransform: 'uppercase', fontSize: '0.75em',paddingRight:".8em"}}>View a question to resume</div>
      <div classname='timerdisplay'>{`Timer Paused`}</div>
    </div>
  </div>
    )
  }
}

Clock = connect(state => ({user: state}))(Clock);

const MastersNavElements = (props) => {

    return (
      <Nav className="ml-auto align-items-center">
        <Clock  {...props} pauseState={props.pauseState} updatePauseState={props.updatePauseState} updatePauseCount={props.updatePauseCount} pauseCount={props.pauseCount}/>
        <BuyTimeNavElement costPerMinute={props.costPerMinute}/>
      </Nav>
    );

}

class AppNavbar extends Component {
  render() {
    return (
      <Navbar className="navbar-dark sticky-top" color="dark">
        <NavbarBrand tag={Link} to="/dashboard" text-color="white">MoMath Masters</NavbarBrand>
        {window.APP_FLAVOR === 'MASTERS' && <MastersNavElements {...this.props} pauseState={this.props.pauseState} updatePauseState={this.props.updatePauseState} updatePauseCount={this.props.updatePauseCount} pauseCount={this.props.pauseCount} costPerMinute={this.props.costPerMinute}/>}
      </Navbar>
    )
  }
}

export default AppNavbar;