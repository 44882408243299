import React, { Component } from 'react';

import { Container } from 'reactstrap';

const Selector = ({ setSelection }) => {

  const buttonStyle = {
    width: '150px',
    height: '150px',
    marginBottom: '1rem',
    fontSize: '5rem',
    fontWeight: 'bold',
    background: 'none',
    borderRadius: '0.25em',
    border: '1px solid black',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }

  const buttons = [];
  for (let i=1; i<11; i++) {
    const val = i % 10;
    buttons.push(
      <div style={buttonStyle} key={val} onClick={() => setSelection(val)}>{val}</div>
    );
  }

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    width: '500px',
    justifyContent: 'space-around'
  }

  return (
    <div style={containerStyle}>{buttons}</div>
  );
}

const Displayer = ({ value, setSelection }) => {
  return (
    <div style={{width: '100%', textAlign: 'center', fontSize: '48rem', fontWeight: 'bold', height: '1000px'}} onClick={() => setSelection(null)}>
      <div style={{marginTop: '-200px'}}>{value}</div>
    </div>
  )
}

class FinalRoundPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: null
    };

    this.setSelection = this.setSelection.bind(this);
  }

  setSelection(selection) {
    this.setState({selection});
  }

  render() {
    const message = (this.state.selection === null) ?
      'Select the number corresponding to your answer and turn the iPad toward the audience.'
      :
      'Press anywhere to return to answer selection.';

    return (
      <Container>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', height: 'calc(100vh - 104px)', justifyContent: 'space-around'}}>
          <div style={{padding: '1em 0'}}>{message}</div>
          { this.state.selection === null ?
            <Selector setSelection={this.setSelection}></Selector>
            :
            <Displayer value={this.state.selection} setSelection={this.setSelection}></Displayer>}
        </div>
      </Container>
    );
  }
}

export default FinalRoundPage;