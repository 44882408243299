import React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';

let AlertBar = ({timeRemaining,endTime,time,timerAutoStart}) => {
  if(timeRemaining > 0){
    if(time !== null){
      return null;
    }else{
      if(timerAutoStart < 15*60 && timerAutoStart > 0){
        // Warn user time is running out
        var minsToTimer = Math.floor(timerAutoStart/60)+1;
        return(
          <Alert color="warning">
            <h2 style={{fontSize:'1.5em', fontWeight: 'bold'}}>Heads up!</h2>
            <p>This round of the Masters is nearing the final 45 minutes!</p>
            <p>Your timer will automatically start in {minsToTimer} minute{minsToTimer > 1 ? "s" : ""}</p>
          </Alert>
        );
      }else if((timerAutoStart === 0 || timerAutoStart < 0)){
        // Notify user that their timer was auto-started for the final 45 mins
        return(
          <Alert color="warning" className="text-center">Your timer was started automatically for the final 45 minutes of the round.</Alert>
        );
      }
    }
    return null;
  }

  const style = {
    position: '-webkit-sticky',
    top: '56px',
    zIndex: '1050',
    padding: '1em',
    color: '#fff',
    background: '#dc3545',
  }

  if(endTime < Math.floor(new Date().getTime()/1000)){
    return(
      <div style={style}>
      <h2 style={{fontSize: '1.5em', fontWeight: 'bold'}}>This round of the Masters has ended.</h2>
      Thanks for playing!
      </div>
    );
  }
  return (
    <div style={style}>
    <h2 style={{fontSize: '1.5em', fontWeight: 'bold'}}>You are out of time!</h2>
    {endTime > Math.floor(new Date().getTime()/1000)+(5.5*60) && <div>More time can be purchased using the button on the upper right.</div>}
    {endTime > Math.floor(new Date().getTime()/1000)+(5.5*60)}
    </div>
  );
}

AlertBar = connect(state => ({timeRemaining: state.remaining, endTime: state.endTime, time: state.time, timerAutoStart: state.timerAutoStart}))(AlertBar);

export default AlertBar;
