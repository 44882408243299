import React from 'react';

import { ListGroup, ListGroupItem } from 'reactstrap';

const prettyPrintNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const leaderTable = ({ winners }) => {
  if(winners.length === 0){
    var empty = [...Array(25)].map((_,i) => {
      return (
        <ListGroupItem key={i}>
          <div className="d-flex align-items-center">
            <div style={{width: '8rem'}} className="text-center"><h1 style={{fontSize: '3em', fontWeight: 'normal'}}>{i+1}</h1></div>
            <div className="d-flex flex-column ml-3">
            </div>
          </div>
        </ListGroupItem>
      );
    });
    return(
      <ListGroup>
        {empty}
      </ListGroup>
    )
  }
  const rankings = winners.map((person, i) => {
    const displayName = person.handle === null ? `${person.first_name} ${person.last_name}` : person.handle
    return (
      <ListGroupItem key={i}>
        <div className="d-flex align-items-center">
          <div style={{width: '8rem'}} className="text-center"><h1 style={{fontSize: '3em', fontWeight: 'normal'}}>{person.ranking}</h1></div>
          <div className="d-flex flex-column ml-3">
            <h2>{ displayName } - {prettyPrintNumber(person.points_cat1 + person.points_cat2 + person.points_cat3)}</h2>
            <div className="d-flex align-items-center my-2">
              <div>Math Classics <strong>{prettyPrintNumber(person.points_cat1)}</strong></div>
              <div className="pl-5">Gardner Greats <strong>{prettyPrintNumber(person.points_cat2)}</strong></div>
              <div className="pl-5">Math Pulse <strong>{prettyPrintNumber(person.points_cat3)}</strong></div>
            </div>
          </div>
        </div>
      </ListGroupItem>
    );
  });

  return (
    <ListGroup>
      {rankings}
    </ListGroup>
  )
}

export default leaderTable;