import React, { Component } from 'react';
import { Redirect,Route,} from 'react-router-dom';


class CheckinForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {id:"",submitted:false};

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.onClick = this.onClick.bind(this);
    }

    onChange(event) {
        this.setState({id: Number(event.target.value)});
    }
    
    async onSubmit(event) {
       this.setState({submitted:true,})
        event.preventDefault();
        
    }



    render (){

        if( !this.state.submitted){
            return(
                <form onSubmit={this.onSubmit}>
                    <label>
                    User Number
                    <input type="number" value={this.state.value} onChange={this.onChange} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            )
        }
        if (typeof this.state.id === 'number' && this.state.submitted)
        return(
            <Redirect to={`/user/login/${this.state.id}`}/>
        )
    }


}

export default CheckinForm
