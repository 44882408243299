import React from 'react';

import { Table } from 'reactstrap';

const ResultsTable = (props) => {
  if (!props.values || props.values.length === 0) {
    return null;
  }
  const keys = Object.keys(props.values[0]);
  return (
    <Table>
      <thead>
        <tr>
          {keys.map((k) => {
            const title = k.split('_').join(' ');
            let formattedTitle = title[0].toUpperCase() + title.slice(1);
            // Ha, this is too upsetting to let go.
            if (formattedTitle === 'Ipad') {
              formattedTitle = 'iPad';
            }
            return <th key={k}>{formattedTitle}</th> })
          }
        </tr>
      </thead>
      <tbody>
        {props.values.map((v, i) => {
          return (
            <tr key={i}>
              { keys.map((k) => {
                const value = v[k];
                let formatted;
                if (isNaN(parseFloat(value))) {
                  try {
                    formatted = value.join(', ');
                  } catch (err) {
                    formatted = value;
                  }
                } else {
                  formatted = parseFloat(value).toFixed(1);
                  if (/\.0/.test(formatted)) {
                    formatted = formatted.split('.')[0];
                  }
                }
                return <td key={k}>{ formatted }</td> })
              }
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ResultsTable;