import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, FormGroup, Input, Modal } from 'reactstrap';

import { setTime,addTime } from '../store';

class BuyTimeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '5',
      isSubmitting: false,
      message: '',
      alert: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    

    if(Math.floor(new Date().getTime()/1000)+this.props.user.remaining+(5.25*60) > this.props.user.endTime){
      this.state = ({value:'5',isSubmitting: true, message: `Purchases of additional time can provide continued access only until the end of the round.`,alert:true});
    }else if(this.props.user.time === null){
      this.state = ({value:'5',isSubmitting: true, message: `You must first view one question to add more time.`, alert:true});
    }
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({isSubmitting: true});
    const nSeconds = parseInt(this.state.value, 10) * 60;
    if(this.props.user.time === null){
      this.state = ({isSubmtting: true, message: `You must first view one question to add more time.`,alert:true});
      return;
    }
    if(this.props.user.time+this.props.user.remaining+nSeconds > this.props.user.endTime){
      this.setState({isSubmitting:false, message: `Cannot add this much time as this would go over the end of the first round.`,alert:true});
      return;
    }
    const req = await fetch(`/api/users/me`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({additional_time: nSeconds})
    });
    if (req.status === 200) {
      this.setState({isSubmitting: false, message: `${this.state.value} minutes have been added to your clock.`,alert:false});
      this.props.dispatch(addTime(nSeconds));
      await setTimeout(() => {
        window.location.reload()
      }, 300);
    }
  }

  render() {
    const costPerMinute = parseInt(this.props.costPerMinute, 10);
    return (
      <div>
        <h4 className="my-2">Add time</h4>
        {this.state.message.length > 0 && <Alert color={this.state.alert ? "danger" : "success"}>{this.state.message}</Alert>}
        <small>
          By adding time, you are agreeing to make a binding contribution to the National Museum of
          Mathematics, a 501(c)(3) not-for-profit organization.
        </small>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input value={this.state.value} type="select" name="timeSelect" id="timeSelect" onChange={this.handleChange}>
              <option value="5">5 minutes (${costPerMinute*5})</option>
              <option value="10">10 minutes (${costPerMinute*10})</option>
              <option value="15">15 minutes (${costPerMinute*15})</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Button type="submit" color="primary" disabled={this.state.isSubmitting} block>Add</Button>
          </FormGroup>
        </Form>

      </div>
    )
  }
}

BuyTimeForm = connect(state => ({user: state}))(BuyTimeForm);

export default BuyTimeForm;